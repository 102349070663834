import React, { createContext, useReducer, useContext, useEffect } from 'react'
import { track } from '../../analytics'
import { CustomerContext } from './CustomerContext'
import { executeAtLeast } from '../../util'
import { httpFetch } from '../../util/httpFetch'
import { notifyDemandError } from '../../util/notify-slack'

const CheckoutContext = createContext()

const initialState = {
  deliveryInfos: '',
  doorCode: '',
  floorRoomStand: '',
  deliveryCompany: '',
  deliveryStreet: '',
  deliveryCity: '',
  deliveryZip: '',
  deliveryCountry: '',
  contactFirstName: '',
  contactLastName: '',
  contactEmail: '',
  contactPhone: '',
  billingCompany: '',
  billingStreet: '',
  billingCity: '',
  billingZip: '',
  billingCountry: '',
  billingEmail: '',
  billingPhone: '',

  options: {
    coffeeTea: false,
    coldDrinks: false,
    alcohols: false,
    service: false,
  },
  notes: '',

  coupon: '',

  isSameBillingInfo: true,
  isFormComplete: false,

  submitting: false,
  submitted: false,

  error: null,

  demandId: null,
  isMailSent: false,
  isHistoryCreated: false,
}

const reducer = (state, { type, payload, analytics }) => {
  switch (type) {
    case 'RESET':
      return initialState
    case 'UPDATE': {
      const updatedState = { ...state, ...payload }
      return {
        ...updatedState,
        isFormComplete: computeFormComplete(updatedState),
      }
    }
    case 'TOGGLE_SAME_BILLING_ADDRESS': {
      let updatedState
      if (payload) {
        updatedState = {
          ...state,
          billingCompany: initialState.billingCompany,
          billingStreet: initialState.billingStreet,
          billingCity: initialState.billingCity,
          billingZip: initialState.billingZip,
          billingCountry: initialState.billingCountry,
          billingEmail: initialState.billingEmail,
          billingPhone: initialState.billingPhone,
          isSameBillingInfo: true,
        }
      } else {
        updatedState = { ...state, isSameBillingInfo: false }
      }
      return {
        ...updatedState,
        isFormComplete: computeFormComplete(updatedState),
      }
    }
    case 'RESET_BILLING_ADDRESS': {
      const updatedState = {
        ...state,
        billingStreet: initialState.billingStreet,
        billingZip: initialState.billingZip,
        billingCity: initialState.billingCity,
        billingCountry: initialState.billingCountry,
      }
      return {
        ...updatedState,
        isFormComplete: computeFormComplete(updatedState),
      }
    }
    case 'SUBMIT':
      if (analytics) {
        track('SubmitCheckout', {
          category: `Category_${analytics.menuCategory}`,
          label: `Menu_${analytics.menuId}`,
          ...analytics,
        })
      }
      return { ...state, submitting: true }
    case 'STRIPE_TOKEN_FAILED':
      console.error(payload)
      return state
    case 'SUBMIT_SUCCESS':
      return {
        ...state,
        submitting: false,
        submitted: true,
        error: null,
        ...payload,
      }
    case 'SUBMIT_FAILED':
      console.error(payload)
      return {
        ...state,
        submitting: false,
        submitted: true,
        ...payload,
      }
    default:
      return state
  }
}

function computeFormComplete(state) {
  return !!(
    state.deliveryCompany.length &&
    state.deliveryStreet.length &&
    state.deliveryZip.length &&
    state.deliveryCity.length &&
    state.contactFirstName.length &&
    state.contactEmail.length &&
    state.contactPhone.length &&
    (state.isSameBillingInfo ||
      (state.billingCompany.length &&
        state.billingStreet.length &&
        state.billingCity.length &&
        state.billingZip.length &&
        state.billingCountry.length &&
        state.billingEmail.length &&
        state.billingPhone.length))
  )
}

function CheckoutContextProvider(props) {
  const { customer } = useContext(CustomerContext)
  const [state, dispatch] = useReducer(reducer, initialState)
  const value = { state, dispatch }

  useEffect(() => {
    if (customer) {
      const stateWithCustomer = {
        contactFirstName: customer.firstName ? customer.firstName : '',
        contactLastName: customer.lastName ? customer.lastName : '',
        contactPhone: customer.phone ? customer.phone : '',
        contactEmail: customer.email ? customer.email : '',
      }
      dispatch({ type: 'UPDATE', payload: stateWithCustomer })
      value.state = stateWithCustomer
    }
  }, [customer])
  return (
    <CheckoutContext.Provider value={value}>
      {props.children}
    </CheckoutContext.Provider>
  )
}

// Inspired by https://kentcdodds.com/blog/how-to-use-react-context-effectively
async function sendDemand(dispatch, demand) {
  dispatch({ type: 'SUBMIT' })

  try {
    const response = await executeAtLeast(
      () =>
        httpFetch(`${process.env.GATSBY_VENUS_URL}/demands`, {
          method: 'POST',
          body: demand,
        }),
      1000,
    )

    dispatch({
      type: 'SUBMIT_SUCCESS',
      payload: {
        demandId: response.demandId,
        isMailSent: response.isMailSent,
        isHistoryCreated: response.isHistoryCreated,
      },
    })

    return {
      status: DEMAND_STATUS.OK,
      response,
    }
  } catch (error) {
    try {
      await notifyDemandError(demand, error)
      dispatch({ type: 'RESET' })

      return {
        status: DEMAND_STATUS.NEED_MANUAL_OPERATION,
      }
    } catch (error) {
      console.error(error)

      dispatch({
        type: 'SUBMIT_FAILED',
        payload: {
          error,
        },
      })

      return { status: DEMAND_STATUS.KO }
    }
  }
}

const DEMAND_STATUS = {
  OK: 'OK',
  NEED_MANUAL_OPERATION: 'NEED_MANUAL_OPERATION',
  KO: 'KO',
}

const CheckoutContextConsumer = CheckoutContext.Consumer

export {
  CheckoutContext,
  CheckoutContextProvider,
  CheckoutContextConsumer,
  sendDemand,
  DEMAND_STATUS,
}
export default CheckoutContext
