import drinksIcon from '../../assets/icons/wine.svg'
import serviceIcon from '../../assets/icons/waiter.svg'
import furnituresIcon from '../../assets/icons/buffet.svg'
import recyclingIcon from '../../assets/icons/recycling.svg'

import coffeeIcon from '../../assets/icons/coffee-cup.svg'
import juiceIcon from '../../assets/icons/juice.svg'
import beerIcon from '../../assets/icons/beer_bottles.svg'
import wineIcon from '../../assets/icons/cheers_wine.svg'
import champainIcon from '../../assets/icons/champagne.svg'
import otherIcon from '../../assets/icons/coconut.svg'

export const defaultCategories = [
	

  { label: `Fêtes de fin d'année`, value: `noel` },
  { label: `Galette des rois`, value: `galette-des-rois` },
  { label: 'Petit déjeuner', value: `petit-dejeuner` },
  { label: 'Cocktail déjeunatoire', value: `cocktail-dejeunatoire` },
  { label: 'Pause gourmande', value: `pause-gourmande` },
  { label: 'Cocktail apéritif', value: `cocktail-aperitif` },
  { label: 'Cocktail dînatoire', value: `cocktail-dinatoire` },
  { label: 'Journée complète', value: `journee-complete` },
  { label: 'Planches à partager', value: `planches-a-partager` },
  { label: 'Animations', value: `animation` },
  { label: 'Déjeuner buffet', value: `dejeuner-buffet` },
  { label: 'Plateaux repas', value: `plateaux-repas` },
  { label: 'Evénement du mois', value: `evenement-du-mois` },
  { label: 'Noël 2024 Paris', value: `noel-paris` },
  { label: 'Noël 2024 Lyon', value: `noel-lyon` },
  

]

export const defaultCities = [
  { label: 'Paris', value: 75000 },
  { label: 'Lyon', value: 69000 },
]

export const defaultOptions = [
  { label: 'Boissons', value: `drinks`, icon: drinksIcon, selected: false },
  { label: 'Staff', value: `service`, icon: serviceIcon, selected: false },
  {
    label: 'Mobilier',
    value: `furnitures`,
    icon: furnituresIcon,
    selected: false,
  },
  {
    label: 'Gestion des déchets',
    value: `recycling`,
    icon: recyclingIcon,
    selected: false,
  },
]

export const defaultDrinks = [
  { label: 'Café / Thé', value: `coffee-tea`, icon: coffeeIcon },
  { label: 'Jus', value: `juice`, icon: juiceIcon },
  { label: 'Bière', value: `beer`, icon: beerIcon },
  { label: 'Vin', value: `wine`, icon: wineIcon },
  { label: 'Champagne', value: `champagne`, icon: champainIcon },
  { label: 'Autre', value: `autre`, icon: otherIcon },
]

export const defaultNbGuestsTypes = [
  { label: 'En moyenne par banquet', value: `average` },
  { label: 'Au total', value: `total` },
]

export const defaultBudgetTypes = [
  { label: 'Par personne', value: `perPers` },
  { label: 'Au total', value: `total` },
]

export const defaultQuantities = [
  { label: 'Grignotage/apéritif', value: `medium` },
  { label: 'Repas complet', value: `large` },
]

export const defaultConstraints = [
  { label: '100% végétarien', value: `full_veggie` },
  { label: '100% sans porc', value: `no_pork` },
]

export const defaultGuestTypes = [
  { label: 'Collègues', value: `colleagues` },
  { label: 'Direction / Top-management', value: `directors` },
  { label: 'Clients / Partenaires', value: `clients` },
  { label: 'VIP', value: `vip` },
  { label: 'Étudiants', value: `students` },
  { label: 'Grand public', value: `generalPublic` },
]

export const defaultBudgets = [5, 10, 15, 20]

export const defaultBudgetsPerPersonWithoutBanquetType = {
  'galette-des-rois': [4, 6, 8, 12],
  noel: [14, 16, 23, 30],
  'petit-dejeuner': [4, 7, 12],
  'cocktail-dejeunatoire': [16, 21, 25, 30],
  'pause-gourmande': [3, 4, 6, 8],
  'cocktail-aperitif': [5, 10, 15, 20],
  'cocktail-dinatoire': [16, 21, 25, 30],
}

export const defaultBudgetsPerPerson = {
  'galette-des-rois': {
    traditional: [4, 6, 8, 12],
    terroir: [4, 6, 8, 12],
    original: [4, 6, 8, 12],
  },
  noel: {
    traditional: [14, 16, 23, 30],
    terroir: [14, 16, 23, 30],
    original: [16, 22, 25, 32],
  },
  'petit-dejeuner': { traditional: [4, 7, 12], original: [4, 7, 12] },
  'cocktail-dejeunatoire': {
    traditional: [16, 21, 25, 30],
    terroir: [16, 21, 25, 30],
    original: [16, 21, 25, 30],
  },
  'pause-gourmande': { traditional: [3, 4, 6, 8], original: [3, 4, 6, 8] },
  'cocktail-aperitif': {
    traditional: [5, 10, 15, 20],
    terroir: [5, 10, 15, 20],
    original: [5, 10, 15, 20],
  },
  'cocktail-dinatoire': {
    traditional: [16, 21, 25, 30],
    terroir: [16, 21, 25, 30],
    original: [16, 21, 25, 30],
  },
}

export const defaultEventFramings = [
  { label: 'Professionnel ou associatif', value: `b2b` },
  { label: 'Privé (famille ou amis)', value: `b2c` },
]

export const defaultBanquetDurations = [
  { label: '< 45min', value: `shortD` },
  { label: '45min à 1h30', value: `mediumD` },
  { label: '> 1h30', value: `longD` },
]

export interface BanquetType {
  label: string
  value: string
}

export const defaultBanquetTypes: BanquetType[] = [
  { label: 'Traditionnel', value: 'traditional' },
  { label: 'Terroir', value: 'terroir' },
  { label: 'Original', value: 'original' },
]

  

