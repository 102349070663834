// https://developers.google.com/analytics/devguides/collection/analyticsjs/

export default config => {
  const { UA, dimension: { name: dName, value: dValue } = {} } = config
  return {
    config,
    name: `google analytics`,
    snippet: `
      window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
      ga('create', '${UA}', 'auto');
      ${dName && dValue ? `ga('set', '${dName}', '${dValue}')` : ''}
    `,
    pageview: ({ pathname, href }) =>
      window.ga('set', 'page', pathname) ||
      window.ga('send', {
        hitType: 'pageview',
        page: pathname,
        location: href,
      }),
    track: ({ eventName, extra = {} }) =>
      window.ga('send', {
        hitType: 'event',
        eventAction: eventName,
        eventCategory: extra.category,
        eventLabel: extra.label,
      }),
  }
}
