import React from 'react'
import '../reset.css'
import Helmet from 'react-helmet'
import StructuredData from '../StructuredData.json'
import { StaticQuery, graphql } from 'gatsby'
import { CheckoutContextProvider } from './context/CheckoutContext'
import { EventContextProvider } from './context/EventContext'
import { ModalContextProvider } from './context/ModalContext'
import { PhoneContextProvider } from './context/PhoneContext'
import { AuthenticationContextProvider } from './context/AuthenticationContext'
import { CustomerContextProvider } from './context/CustomerContext'

const LayoutWithData = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
            site_name
            image
          }
        }
      }
    `}
    render={result => (
      <Layout siteMetadata={result.site.siteMetadata} {...props} />
    )}
  />
)

const Layout = ({
  siteMetadata: { siteUrl, title, description, author, site_name, image },
  children,
  location,
}) => {
  return (
    <AuthenticationContextProvider>
      <CustomerContextProvider>
        <PhoneContextProvider>
          <ModalContextProvider>
            <EventContextProvider>
              <CheckoutContextProvider>
                <Helmet>
                  <title>{title}</title>
                  <html lang="fr" />
                  <meta name="description" content={description} />
                  <meta name="author" content={author} />
                  <meta property="og:title" content={title} />
                  <meta property="og:type" content="website" />
                  <meta
                    property="og:url"
                    content={siteUrl + location.pathname}
                  />
                  <meta property="og:description" content={description} />
                  <meta property="og:site_name" content={site_name} />
                  <meta property="og:image" content={image} />
                  {/* Schema.org */}
                  <script type="application/ld+json">
                    {JSON.stringify(StructuredData)}
                  </script>
                </Helmet>
                {children}
              </CheckoutContextProvider>
            </EventContextProvider>
          </ModalContextProvider>
        </PhoneContextProvider>
      </CustomerContextProvider>
    </AuthenticationContextProvider>
  )
}

export default LayoutWithData
