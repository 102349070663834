import fetch from 'isomorphic-unfetch'
import Cookie from 'js-cookie'
import { fetchJWT } from '../components/context/AuthenticationContext'

export class ClientError extends Error {
  userMessage

  constructor(userMessage, techMessage) {
    super(techMessage || userMessage)
    this.userMessage = userMessage || techMessage
  }
}

export class HttpError extends ClientError {
  status

  constructor({ status, statusCodeValue, errors, error }) {
    super(
      error ? error : errors ? errors.join('\n') : '',
      `[${status || statusCodeValue}] ${
        error ? error : errors ? errors.join('\n') : ''
      }`,
    )
    this.status = status
  }
}

/**
 * Custom fetch function to set good defaults, add auth token and handle response/error well
 **/
export const httpFetch = async (url, fetchOptions = {}) => {
  let authorizationHeader
  // by default add Authorization header to all requests;
  if (!fetchOptions.noAuth) {
    let jwt = fetchOptions.jwt || Cookie.get('jwt')
    if (jwt) {
      const refreshJwt = fetchOptions.refreshJwt || Cookie.get('rjwt')
      const { access_token } = await fetchJWT({ refreshJwt })
      jwt = access_token
      authorizationHeader = {
        Authorization: `Bearer ${jwt}`,
      }
    }
  }

  // eslint-disable-next-line require-atomic-updates
  fetchOptions.headers = {
    // add the authentication token when provided
    ...authorizationHeader,
    // set content type as JSON by default for POST request
    ...(fetchOptions.method &&
      (fetchOptions.method.toLowerCase() === 'post' ||
        fetchOptions.method.toLowerCase() === 'put') && {
        'Content-Type': 'application/json',
      }),
    // override any previous default config with provided header config
    ...fetchOptions.headers,
  }

  if (fetchOptions.body && typeof fetchOptions.body !== 'string') {
    // eslint-disable-next-line require-atomic-updates
    fetchOptions.body = JSON.stringify(fetchOptions.body)
  }

  return fetch(url, fetchOptions)
    .then(async response => {
      let res
      const contentType = response.headers.get('Content-Type')
      if (contentType && contentType.toLowerCase().includes('json')) {
        res = await response.json()
      } else {
        res = await response.text()
      }

      if (response.status >= 300) {
        throw new HttpError(
          res.body ||
            res || { status: response.status, error: response.statusText },
        )
      }
      return res
    })
    .catch(err => {
      console.warn(err)
      throw err
    })
}
