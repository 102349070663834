// https://www.intercom.com/help/the-intercom-platform/track-events-in-intercom
// https://developers.intercom.com/intercom-api-reference/reference#event-metadata-types
// https://developers.intercom.com/installing-intercom/docs/intercom-javascript

export default config => {
  const { app_id } = config
  return {
    config,
    name: `intercom`,
    snippet: `
    window.intercomSettings = {app_id: '${app_id}'};
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${app_id}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
`,
    isBooted: () => window.Intercom.booted,
    pageview: () =>
      window.Intercom('trackEvent', 'pageView', {
        href: window && window.location.href,
        referrer: document && document.referrer,
      }),
    track: ({ eventName, extra }) =>
      window.Intercom('trackEvent', eventName, extra),
    // https://stackoverflow.com/a/46007757/3988308
    lead: ({ email, extra }) =>
      window.Intercom('update', {
        anonymous_email: email,
        ...extra,
      }),
    user: () => ({ email, extra }) =>
      window.Intercom('update', {
        email,
        ...extra,
      }),
    bootEmail: (email, name) =>
      window.Intercom('boot', {
        app_id: app_id,
        email: email,
        name: name,
      }),
    message: message => window.Intercom('showNewMessage', message),
  }
}
