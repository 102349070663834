import React, { createContext, useReducer } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { track } from '../../analytics'
import { globalHistory } from '@reach/router'
import dayjs from 'dayjs'
import {
  defaultBudgetTypes,
  defaultCities,
  defaultCategories,
  defaultDrinks,
  defaultOptions,
  defaultEventFramings,
} from './EventContextDefaults'

const EventContext = createContext()

const initialState = {
  nbGuests: 30,
  nbGuestsForm: null,
  nbGuestsType: null, // on average by banquet
  address: null,
  zipcode: defaultCities[0].value, // Paris 75000
  startDateTime: null,
  endDateTime: null,
  category: defaultCategories[4].value, // cocktail-dinatoire
  moment: null,
  fromLanding: false,
  menu: null,
  options: defaultOptions,
  drinks: defaultDrinks,
  budget: {
    min: null,
    max: null,
    specific: null,
  },
  budgetType: defaultBudgetTypes[0].value, // per pers.
  banquetType: null,
  multiMoment: false,
  constraints: [],
  guestTypes: [],
  notes: '',
  eventFraming: defaultEventFramings[0].value, // professionnel
  banquetDuration: null,
  demandSource: null,
}

const reducer = (state, { type, payload, analytics }) => {
  const trackParams = {
    category: `Page_${globalHistory.location.pathname}`,
    ...analytics,
  }
  switch (type) {
    case 'RESET':
      return initialState
    case 'RESET_ADDRESS':
      return {
        ...state,
        address: initialState.address,
        zipcode: initialState.zipcode,
      }
    case 'UPDATE_CATEGORY':
      track('ChangeCategory', trackParams)
      break
    case 'UPDATE_ZIP':
      track('ChangeZip', trackParams)
      break
    case 'UPDATE_GUESTS':
      track('ChangeGuests', trackParams)
      break
    case 'UPDATE_DELIVERY_DATETIME': {
      track('ChangeDeliveryDatetime', trackParams)

      let endDateTime = dayjs(payload.endDateTime)
      const startDateTime = dayjs(payload.startDateTime)
      if (endDateTime.diff(startDateTime, 'hour') <= 0) {
        endDateTime = endDateTime.hour(startDateTime.hour() + 1)
      }

      return {
        ...state,
        startDateTime: payload.startDateTime,
        endDateTime: endDateTime.toDate(),
      }
    }
    case 'UPDATE_MENU':
      track('ChooseMenu', trackParams)
      break
    case 'UPDATE_OPTIONS':
      track('ToggleOptions', trackParams)
      break
    case 'UPDATE_DRINKS':
      track('ToggleDrinks', trackParams)
      break
    case 'UPDATE_BUDGET':
      track('ChooseBudget', trackParams)
      break
    case 'UPDATE_IS_MULTI_MOMENT':
      track('ChangeIsMultiMoment', trackParams)
      break
    case 'UPDATE_CONSTRAINTS':
      track('ChangeConstraints', trackParams)
      break
    case 'UPDATE_GUEST_TYPES':
      track('ChangeGuestTypes', trackParams)
      break
    case 'UPDATE_EVENT_FRAMING':
      track('ChangeEventFraming', trackParams)
      break
    case 'UPDATE_BANQUET_DURATION':
      track('ChangeBanquetDuration', trackParams)
      break
    case 'UPDATE_DEMAND_SOURCE':
      track('ChangeDemandSource', trackParams)
      break
    case 'UPDATE_BANQUET_TYPE':
      track('ChooseBanquetType', trackParams)
      break
    case 'UPDATE':
      break
    default:
      return state
  }
  return { ...state, ...payload }
}

const EventContextProvider = ({
  categories = [],
  zipcodes = [],
  allDropDownCategoriesFiltred = [],
  ...props
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const value = {
    state,
    dispatch,
    categories,
    zipcodes,
    allDropDownCategoriesFiltred,
  }
  return (
    <EventContext.Provider value={value}>
      {props.children}
    </EventContext.Provider>
  )
}

const EventContextProviderWithData = props => (
  <StaticQuery
    query={graphql`
      query {
        allMenuZipCode {
          edges {
            node {
              code
            }
          }
        }
        allCategories: allContentfulEventTypePage(
          filter: { hideButton: { ne: true } }
        ) {
          edges {
            node {
              slug
              title
              suggestedHours
              hideMenus
            }
          }
        }
        allDropDownCategories: allContentfulEventTypePage {
          edges {
            node {
              slug
              title
              suggestedHours
              hideMenus
            }
          }
        }
      }
    `}
    render={({ allMenuZipCode, allCategories, allDropDownCategories }) => {
      const categories = allCategories.edges.map(({ node }) => ({
        value: node.slug,
        label: node.title,
        ...node,
      }))

      const allDropDownCategoriesFiltred = allDropDownCategories.edges.map(
        ({ node }) => ({
          value: node.slug,
          label: node.title,
          ...node,
        }),
      )

      const sortedCategories = sortByOrder(categories)

      const zipcodes = allMenuZipCode.edges.map(({ node }) => ({
        ...node,
        city: `${node.code.startsWith('75') ? 'Paris' : ''} ${
          node.code.startsWith('69') ? 'Lyon' : ''
        }`.trim(),
      }))

      return (
        <EventContextProvider
          categories={sortedCategories}
          zipcodes={zipcodes}
          allDropDownCategoriesFiltred={allDropDownCategoriesFiltred}
          {...props}
        />
      )
    }}
  />
)

const sortByOrder = list => {
  const allCat = [
    { order: 9, slug: `noel` },
    { order: 10, slug: `galette-des-rois` },
    { order: 1, slug: `petit-dejeuner` },
    { order: 3, slug: `cocktail-dejeunatoire` },
    { order: 2, slug: `pause-gourmande` },
    { order: 5, slug: `cocktail-aperitif` },
    { order: 6, slug: `cocktail-dinatoire` },
    { order: 7, slug: `journee-complete` },
    { order: 11, slug: `planches-a-partager` },
    { order: 12, slug: `animation` },
    { order: 13, slug: `dejeuner-buffet` },
    { order: 4, slug: `plateaux-repas` },
    { order: 8, slug: `evenement-du-mois` },
    { order: 14, slug: `noel-paris` },
    { order: 15, slug: `noel-lyon` },
  ]

  const categoryOrderMap = new Map()
  allCat.forEach(category => {
    categoryOrderMap.set(category.slug, category.order)
  })

  return list.sort((a, b) => {
    const orderA = categoryOrderMap.get(a.slug) || Infinity // Use Infinity for unmatched labels
    const orderB = categoryOrderMap.get(b.slug) || Infinity
    return orderA - orderB
  })
}
/**
 * Will sort categories by suggested hours, taking into account 'falsy' values
 * @param categories
 */
const sortBySuggestedHoursAsc = categories => {
  return sortBy(categories, compareSuggestedHours)
}

const sortBy = (array, compareFn) => {
  const sortedArray = [...array]

  sortedArray.sort(compareFn)

  return sortedArray
}

const compareSuggestedHours = (a, b) => {
  if (!a.suggestedHours && !b.suggestedHours) {
    return 0
  }

  if (!a.suggestedHours && b.suggestedHours) {
    return -1
  }

  if (!b.suggestedHours && a.suggestedHours) {
    return 1
  }

  return a.suggestedHours.localeCompare(b.suggestedHours)
}

const EventContextConsumer = EventContext.Consumer

export {
  EventContext,
  EventContextProviderWithData as EventContextProvider,
  EventContextConsumer,
}
export default EventContext
