import React, { createContext, useEffect, useReducer } from 'react'
import styled from 'styled-components'
import PhoneLink from '../PhoneLink'
import { track } from '../../analytics'
import Modal from '../Modal'
import { globalHistory } from '@reach/router'
import Link, { navigate } from '../Link'
import { Button, StyledButton } from '@monbanquet/crumble'
import { breakpoints, colors } from '../../theme'

const ModalContext = createContext()

const initialState = {
  opened: false,
}

const reducer = (state, { type, analytics }) => {
  switch (type) {
    case 'DISMISS':
      return {
        ...state,
        opened: false,
      }
    case 'OPEN': {
      navigate('/demande/format')
      track('OpenModal', {
        category: `Page_${globalHistory.location.pathname}`,
        ...analytics,
      })
      return state
    }
    default:
      return state
  }
}

function ModalContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const value = { state, dispatch }

  useEffect(() => {
    const dismissModal = () => dispatch({ type: 'DISMISS' })
    window.addEventListener('popstate', dismissModal)
    return () => {
      window.removeEventListener('popstate', dismissModal)
    }
  }, [])

  return (
    <ModalContext.Provider value={value}>
      {props.children}
      <StyledModal
        opened={state.opened}
        dismiss={() => dispatch({ type: 'DISMISS' })}
        title={`Donnez-nous des détails sur votre événement`}
      >
        <p className="text">Remplissez notre formulaire en 3min,</p>
        <p className="text">recevez votre devis en 3 heures :</p>
        <div className="choice">
          <Link
            to={process.env.GATSBY_B2B_FORM_LINK}
            target="_blank"
            rel="noopener noreferrer"
            className="btn"
          >
            <Button
              onClick={() =>
                track('ClickB2B', {
                  label: `From_Modal`,
                  category: `Page_${globalHistory.location.pathname}`,
                })
              }
            >
              Entreprise ou Association
            </Button>
          </Link>
          <Link
            to={process.env.GATSBY_B2C_FORM_LINK}
            target="_blank"
            rel="noopener noreferrer"
            className="btn"
          >
            <Button
              onClick={() =>
                track('ClickB2C', {
                  label: `From_Modal`,
                  category: `Page_${globalHistory.location.pathname}`,
                })
              }
            >
              Entre Amis/Famille
            </Button>
          </Link>
        </div>
        <span className="separator" />
        <div className="call">
          {`ou appelez directement au `}
          <PhoneLink
            onClick={() =>
              track('ClickPhone', {
                label: `From_Modal`,
                category: `Page_${globalHistory.location.pathname}`,
              })
            }
          />
        </div>
      </StyledModal>
    </ModalContext.Provider>
  )
}

const StyledModal = styled(Modal)`
  .choice {
    margin: 35px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .separator {
    font-size: 0.7rem;
    color: #b3b3b3;
    text-align: center;
    display: block;
    margin: 20px auto;
    position: relative;
    background: white;
    margin: 10px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 50%;
      height: 1px;
      background: #ddd;
      top: 50%;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }

  p {
    text-align: center;
  }

  .text {
    margin: 5px 0;
  }

  .btn {
    width: 50%;
    &:first-child {
      margin-right: 20px;
    }
  }

  ${StyledButton} {
    font-size: 0.8rem;
    font-weight: 400;
    width: 100%;
  }

  .call {
    text-align: center;
    color: ${colors.text.light3};
    padding: 10px 0;
    padding-top: 30px;

    a {
      font-weight: 600;
      color: ${colors.brand.normal};
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .call a {
      margin-top: 10px;
      display: block;
    }
    .choice {
      flex-direction: column;

      .btn {
        width: 80%;
        margin: 0;
        &:first-child {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
`

const ModalContextConsumer = ModalContext.Consumer

export { ModalContext, ModalContextProvider, ModalContextConsumer }
export default ModalContext
