import { getQueryParams } from './index'
import { defaultBanquetTypes } from '../components/context/EventContextDefaults'

export interface DemandForm {
  demandId: string
  eventFormat: string
  moment: string
  fromLanding: boolean
  multiMoment: boolean
  contactCompany: string
  contactEmail: string
  contactFirstName: string
  contactLastName: string
  contactPhone: string
  options: string[]
  drinks: string[]
  eventStart: any
  eventEnd: any
  nbGuests: number
  nbGuestsType: any
  veggie: boolean
  noPork: boolean
  guestsTypes: any
  notes: string
  eventFraming: string
  banquetDuration: any
  demandSource: string
  coupon: string
  utmCampaign: string
  utmSource: string
  utmMedium: string
  utmContent: string
  utmTerm: string
  branchOrigin: string
  isSaved: boolean
  isMailSent: boolean
  isHistoryCreated: boolean
  deliveryCity?: string
  deliveryCountry?: string
  deliveryStreet?: string
  deliveryStreetNumber?: number
  deliveryZip?: number
  deliveryAddress?: string
  longitude?: string
  latitude?: string
  specificBudgetAmount?: number
  specificBudgetType?: any
  specificBudget?: any
  budgetMin?: number
  budgetMax?: number
  banquetType?: string
}

const getBanquetType = (banquetType: string): string | undefined => {
  const result = defaultBanquetTypes.find(type => type.value === banquetType)

  return result ? result.label : undefined
}

export function buildAskForQuoteBody({
  categories,
  category,
  moment,
  menu,
  fromLanding,
  demandId,
  isMailSent,
  isHistoryCreated,
  multiMoment,
  deliveryCompany,
  contactFirstName,
  contactLastName,
  contactEmail,
  contactPhone,
  options,
  drinks,
  startDateTime,
  endDateTime,
  nbGuestsForm,
  nbGuestsType,
  constraints,
  guestTypes,
  notes,
  eventFraming,
  banquetDuration,
  banquetType,
  address,
  budget,
  budgetType,
  demandSource,
  coupon,
}): DemandForm {
  const cats = categories.filter(cat => cat.value === category)
  let eventFormat = cats.length ? cats[0].value : 'Format non renseigné'
  if (fromLanding) {
    eventFormat = `${category} ->  ${menu}`
  }
  const queryParams = getQueryParams()
  const body: DemandForm = {
    demandId,
    eventFormat: eventFormat,
    moment: moment,
    fromLanding: fromLanding,
    multiMoment: multiMoment,
    contactCompany: deliveryCompany || `${contactFirstName} ${contactLastName}`,
    contactEmail,
    contactFirstName,
    contactLastName,
    contactPhone,
    options: options.filter(o => o.selected).map(o => o.value),
    drinks: drinks.filter(d => d.selected).map(d => d.value),
    eventStart: startDateTime,
    eventEnd: endDateTime,
    nbGuests: nbGuestsForm,
    nbGuestsType,
    veggie: constraints.filter(c => c === 'full_veggie').length,
    noPork: constraints.filter(c => c === 'no_pork').length,
    guestsTypes: guestTypes,
    notes,
    eventFraming,
    banquetDuration,
    banquetType: getBanquetType(banquetType),
    demandSource,
    coupon,

    // utms
    utmCampaign: queryParams['utm_campaign'],
    utmSource: queryParams['utm_source'],
    utmMedium: queryParams['utm_medium'],
    utmContent: queryParams['utm_content'],
    utmTerm: queryParams['utm_term'],

    // other
    branchOrigin: process.env.GATSBY_NETLIFY_BRANCH || 'localhost',

    isSaved: !!demandId,
    isMailSent,
    isHistoryCreated,
  }

  if (address) {
    body.deliveryCity = address.city || ''
    body.deliveryCountry = address.country || ''
    // If address.street is not filled, it means that user didn't click on the Google Maps suggestion
    // so, we use directly what he typed ( contained in address.formatted)
    body.deliveryStreet = address.street ? address.street : address.formatted
    body.deliveryStreetNumber = address.streetNumber
    body.deliveryZip = address.zip
    body.deliveryAddress = address.formatted
    body.longitude = address.lng
    body.latitude = address.lat
  }

  if (budget.specific) {
    body.specificBudget = true
    body.specificBudgetAmount = budget.specific
    body.specificBudgetType = budgetType
  } else {
    body.specificBudget = false
    body.budgetMin = budget.min
    body.budgetMax = budget.max >= 9999 ? null : budget.max
  }
  return body
}
