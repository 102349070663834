export const colors = {
  brand: {
    normal: '#BC8046',
    dark: '#ff4633',
    dark2: '#bd3a3a',
    inverted: '#fff',
    black: '#000',
    red: '#FF817A',
    beige: '#F0E4D2',
    green: '#0F7762',
  },
  accent: {
    normal: '#ffec9b',
    gold: '#ffbe02',
    darkPink: '#d7b6a2',
    peach: '#f8a37c',
  },
  secondary: {
    light2: '#45aeff',
    light: '#4a90e2',
    normal: '#0984e3',
    dark: '#222f3e',
    inverted: '#fff',
    yellow: '#FFDE89',
    beige: '#E8E9D8',
  },
  background: {
    light: '#F5F5F5',
    semiLight: '#f6f6f6',
    normal: '#ddd',
  },
  text: {
    dark: '#333',
    normal: '#4a4a4a',
    semiLight: '#555',
    light: '#666',
    light2: '#777',
    light3: '#9b9b9b',
    blue: '#1c4257',
  },
  warning: '#ff9d00',
  pastels: {
    darkGold: '#bc8e15',
    gold: '#f4e7da',
    blue: '#e0eef6',
    rose: '#f0dddd',
  },
  env: {
    development: '#7f00ff',
    staging: '#ff8700',
    pp: '#0084ff',
  },
}

export const borders = {
  radius: { normal: '7px', small: '4px', inputs: '2px' },
  color: { normal: '#ddd', light: '#e8e8e8', light2: '#f3f3f3', pg: '#cad1e3' },
}

export const layout = {
  maxWidth: 1280,
  width: 'calc(100% - 5vw)',
  containerPadding: { normal: '20px', mobile: '10px' },
  sectionPadding: '50px',
  navbarHeight: { normal: 70, mobile: 100 },
  topCtaHeaderFormHeight: {
    normal: 'calc(100vh - 144px)',
    mobile: 'calc(100vh - 332px)',
  },
  footerHeight: { normal: 175, mobile: 232 },
}

// to avoid changing all website width, set temporary layout
export const homeLayout = {
  width: 'calc(100% - 12vW)',
  smallWidth: 'calc(100% - 5vW)',
}

export const shadows = {
  simple: '0 4px 6px 0 rgba(126,126,126,0.11)',
  simple2: '0 1px 7.5px 0 rgba(0, 0, 0, 0.15)',
  both:
    '0 4px 6px 0 rgba(126, 126, 126, 0.11), 0 1px 3px 0 rgba(0, 0, 0, 0.08)',
  bothHigh: '0 6px 8px 0 rgba(70,70,70,0.2), 0 3px 8px 0 rgba(0,0,0,0.1)',
  bothDiffused:
    '0 4px 25px 0 rgba(126, 126, 126, 0.11), 0 1px 15px 0 rgba(0, 0, 0, 0.08)',
}

export const transitions = { all: 'all 150ms ease-in-out' }

export const breakpoints = {
  smallMobile: 350,
  mobile: 450,
  tablet: 768,
  desktop: 1024,
}
