module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://monbanquet.fr","exclude":["/404","/commande","/merci","/demande/merci"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Monbanquet.fr","short_name":"Monbanquet","start_url":"/","background_color":"#fff","theme_color":"#fc4c4c","display":"minimal-ui","icon":"static/favicons/favicon-prod.jpeg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e214c3b23e97089ec7b8b7880684fcd7"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
