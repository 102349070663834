import { Modal } from '@monbanquet/crumble'
import React from 'react'
import styled from 'styled-components'
import { breakpoints, colors } from '../theme'

const SiteModal = ({ dismiss, opened = false, title, children, ...props }) => (
  <StyledSiteModal opened={opened} dismiss={dismiss} {...props}>
    <div className="top-bar">
      <h3>{title}</h3>
      <button className="close" onClick={dismiss} type="button">
        ×
      </button>
    </div>
    <div className="content">{children}</div>
  </StyledSiteModal>
)

const StyledSiteModal = styled(Modal)`
  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${colors.background.semiLight};
    color: ${colors.text.light};
    padding: 20px;
  }
  h3 {
    font-weight: 500;
    font-size: 1.2rem;
    letter-spacing: 0.2px;
    color: ${colors.text.blue};
  }
  .close {
    appearance: none;
    background: none;
    border: none;
    font-size: 2rem;
    color: #b3b3b3;
    cursor: pointer;
    transition: all 150ms ease;
    outline: none;
    &:hover,
    &:focus {
      color: #777;
    }
  }

  .content {
    padding: 20px;
    color: ${colors.text.light2};
    &,
    p {
      font-size: 1rem;
    }
  }

  .actions {
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: center;

    .cancel {
      background: transparent;
      border: 1px solid #ddd;
      color: #555;
      flex-basis: 40%;
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    height: 100%;
    border-radius: 0;
  }
`

export default SiteModal
export { StyledSiteModal as StyledModal }
