import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthenticationContext } from './AuthenticationContext'
import { httpFetch } from '../../util/httpFetch'
import Cookie from 'js-cookie'

const CustomerContext = createContext({})

const CustomerContextProvider = ({ customer, children, ...props }) => {
  const { user } = useContext(AuthenticationContext)
  const [getCustomer, setCustomer] = useState(customer)

  useEffect(() => {
    if (typeof user === 'undefined') {
      setCustomer(undefined)
    } else if (user === null) {
      setCustomer(null)
    } else if (user) {
      const jwt = Cookie.get('jwt')
      const refreshJwt = Cookie.get('rjwt')

      if (!jwt || !refreshJwt) {
        setCustomer(null)
      } else {
        fetchCustomer(jwt, refreshJwt).then(res => {
          setCustomer(res)
        })
      }
    }
  }, [user])

  const value = {
    customer: getCustomer,
    setCustomer,
  }

  return (
    <CustomerContext.Provider value={value} {...props}>
      {children}
    </CustomerContext.Provider>
  )
}

async function fetchCustomer(jwt, refreshJwt) {
  return httpFetch(`${process.env.GATSBY_VENUS_BACK_URL}/api/account`, {
    jwt,
    refreshJwt,
  })
}

const CustomerContextConsumer = CustomerContext.Consumer

export { CustomerContext, CustomerContextProvider, CustomerContextConsumer }
export default CustomerContext
