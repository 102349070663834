import validateField, { isEmpty } from './validate'
import { buildAskForQuoteBody } from './build-form'
import { globalHistory } from '@reach/router'
import {
  computeNextPage,
  withBaseUri,
  computeNextPageWithBaseUri,
} from './compute-next-page'
import { httpFetch } from './httpFetch'

// TODO: url-search-params is deprecated in favor of use @ungap/url-search-params
import URLSearchParams from 'url-search-params'

function makeQueryString(queryParams) {
  delete queryParams.queryString // supposidely coming from getQueryParams(), remove the queryString special param.
  return '?' + new URLSearchParams(queryParams).toString()
}

function getQueryParams() {
  const queryString = globalHistory.location.search
  const queryParams = { queryString }
  const params = new URLSearchParams(queryString)
  for (const pair of params.entries()) {
    queryParams[pair[0]] = pair[1]
  }
  return queryParams
}

function toQueryString(params) {
  let queryString = '?'
  for (const key in params) {
    queryString += `${key}=${params[key]}&`
  }
  return queryString.slice(0, -1)
}

function isBrowser() {
  return typeof window !== 'undefined'
}

function getWindowLocation() {
  if (isBrowser()) {
    return window.location
  } else {
    return {}
  }
}

function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component'
}

// http://mitchvollebregt.com/snippets-js-promise-minimum-execution-time/
// Promise.all without failing on a rejectd promise
export function promiseAllReflect(promises: Promise<any>[] = []) {
  const reflect = promise =>
    promise.then(
      value => ({ value, status: 'fulfilled' }),
      error => ({ error, status: 'rejected' }),
    )

  return Promise.all(promises.map(reflect))
}

export function executeAtLeast(fn, time = 0) {
  return promiseAllReflect([
    new Promise(resolve => setTimeout(resolve, time)),
    fn(),
  ]).then(([, result]) => {
    if (result.status === 'rejected') {
      return Promise.reject(result.error)
    } else {
      return Promise.resolve(result.value)
    }
  })
}

export {
  getQueryParams,
  toQueryString,
  validateField,
  isEmpty,
  getWindowLocation,
  isBrowser,
  getDisplayName,
  buildAskForQuoteBody,
  makeQueryString,
  computeNextPage,
  withBaseUri,
  computeNextPageWithBaseUri,
  httpFetch,
}
