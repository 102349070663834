export type NavigationRoutes =
  | 'root'
  | 'pg-menu'
  | 'adresse'
  | 'date'
  | 'debut'
  | 'convives'
  | 'regime'
  | 'budget'
  | 'budget-specifique'
  | 'options'
  | 'noms'
  | 'societe'
  | 'email'
  | 'phone'
  | 'source'
  | 'boissons'
  | 'coupon'
  | null

type Context = {
  isUserLoggedIn?: boolean
  isMultiMoments?: boolean
  isBudgetSpecific?: boolean
  containsDrinks?: boolean
  customer?: {
    firstName: string
    lastName: string
    company: string
    email: string
    phone: string
  }
}

function computeNextPage(
  current: NavigationRoutes,
  context?: Context,
): NavigationRoutes {
  const isUserLoggedIn = context && context.isUserLoggedIn

  const route = isUserLoggedIn
    ? flowWhenCustomerIsLoggedIn(current, context)
    : flowForAnonym(current, context)

  return route
}

export const withBaseUri = (baseUri: string) => (
  page: NavigationRoutes,
): string | null => {
  if (!page) {
    return null
  }

  return `/${baseUri}/${page}`
}

export const computeNextPageWithBaseUri = (
  baseUri: string,
  currentPage: NavigationRoutes,
  context?: Context,
): string | null => {
  const prependWithBaseUri = withBaseUri(baseUri)
  const nextPage = computeNextPage(currentPage, context)

  return prependWithBaseUri(nextPage)
}

function flowWhenCustomerIsLoggedIn(
  current: NavigationRoutes,
  context?: Context,
): NavigationRoutes {
  if (current === 'root') {
    return 'adresse'
  }

  if (current === 'pg-menu') {
    return 'adresse'
  }

  if (current === 'adresse') {
    return 'date'
  }

  if (current === 'date') {
    return 'debut'
  }

  if (current === 'debut') {
    return 'convives'
  }

  if (current === 'convives') {
    return 'regime'
  }

  if (current === 'regime') {
    if (context && context.isMultiMoments) {
      return 'budget-specifique'
    }

    return 'budget'
  }

  if (current === 'budget-specifique' || current === 'budget') {
    if (context && context.isBudgetSpecific) {
      return 'budget-specifique'
    }

    return 'options'
  }

  if (current === 'options') {
    if (context && context.containsDrinks) {
      return 'boissons'
    }

    if (context && context.customer) {
      const { firstName, lastName, company, email, phone } = context.customer

      if (!firstName || !lastName) {
        return 'noms'
      }

      if (!company) {
        return 'societe'
      }

      if (!email) {
        return 'email'
      }

      if (!phone) {
        return 'phone'
      }
    }

    return null
  }

  if (current === 'noms') {
    if (context && context.customer) {
      const { company, email, phone } = context.customer

      if (!company) {
        return 'societe'
      }

      if (!email) {
        return 'email'
      }

      if (!phone) {
        return 'phone'
      }
    }

    return null
  }

  if (current === 'societe') {
    if (context && context.customer) {
      const { email, phone } = context.customer

      if (!email) {
        return 'email'
      }

      if (!phone) {
        return 'phone'
      }
    }

    return null
  }

  if (current === 'email') {
    if (context && context.customer) {
      const { phone } = context.customer

      if (!phone) {
        return 'phone'
      }
    }

    return null
  }

  if (current === 'phone') {
    return null
  }

  if (current === 'boissons') {
    if (context && context.customer) {
      const { firstName, lastName, company, email, phone } = context.customer

      if (!firstName || !lastName) {
        return 'noms'
      }

      if (!company) {
        return 'societe'
      }

      if (!email) {
        return 'email'
      }

      if (!phone) {
        return 'phone'
      }
    }

    return null
  }

  return null
}

function flowForAnonym(
  current: NavigationRoutes,
  context?: Context,
): NavigationRoutes {
  if (current === 'root') {
    return 'adresse'
  }

  if (current === 'pg-menu') {
    return 'adresse'
  }

  if (current === 'adresse') {
    return 'date'
  }

  if (current === 'date') {
    return 'debut'
  }

  if (current === 'debut') {
    return 'convives'
  }

  if (current === 'convives') {
    return 'regime'
  }

  if (current === 'regime') {
    if (context && context.isMultiMoments) {
      return 'budget-specifique'
    }

    return 'budget'
  }

  if (current === 'budget-specifique' || current === 'budget') {
    if (context && context.isBudgetSpecific) {
      return 'budget-specifique'
    }

    return 'options'
  }

  if (current === 'options') {
    if (context && context.containsDrinks) {
      return 'boissons'
    }

    return 'noms'
  }

  if (current === 'boissons') {
    return 'noms'
  }

  if (current === 'noms') {
    return 'societe'
  }

  if (current === 'societe') {
    return 'email'
  }

  if (current === 'email') {
    return 'phone'
  }

  if (current === 'phone') {
    return null
  }

  return null
}

export { computeNextPage }
