/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import { initiatedScripts, pageview } from './src/analytics'
import Layout from './src/components/Layout'

// https://developers.google.com/web/fundamentals/app-install-banners/
window.addEventListener('beforeinstallprompt', e => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault()
  // Stash the event so it can be triggered later.
  const deferredPrompt = e
})

// unregister evenutally installed service-worker
if (window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    for (let registration of registrations) {
      registration.unregister()
    }
  })
}

export const onRouteUpdateDelayed = e =>
  window.dispatchEvent(new CustomEvent('routeUpdateDelayed', e))

export const onRouteUpdate = ({ location: { pathname, href } }) => {
  window.dispatchEvent(new CustomEvent('routeUpdate', { location }))
  pageview({ pathname, href })
}
export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)

export const onClientEntry = () => {
  if (['prod', 'pp'].includes(process.env.GATSBY_ENV_NAME)) {
    window.onload = () => {
      setTimeout(() => {
        const intercomScript = document.createElement('script')
        intercomScript.async = true
        intercomScript.innerHTML = initiatedScripts.intercom.snippet
        document.body.appendChild(intercomScript)
      }, 1000)
    }
  }
}
