// https://www.linkedin.com/help/lms/answer/65513

export default config => {
  const { _linkedin_partner_id } = config
  return {
    config,
    name: `linkedin`,
    snippet: `
    window._linkedin_partner_id = '${_linkedin_partner_id}';
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    (function(){var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");
    b.type = "text/javascript";b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);})();
  `,
    noScriptSnippet: `<img height="1" width="1" style="display:none;" alt="" src="https://dc.ads.linkedin.com/collect/?pid=${_linkedin_partner_id}&fmt=gif" />`,
  }
}
