import { globalHistory } from '@reach/router'
import React, { createContext, useEffect, useState } from 'react'
import decodeJWT from 'jwt-decode'
import Cookie from 'js-cookie'
import { httpFetch, ClientError } from '../../util/httpFetch'
import { LOGIN } from '../../util/links'

const AuthenticationContext = createContext(undefined)

export async function getUserIfAuthenticated(refreshJwt) {
  if (!refreshJwt) {
    return null
  }

  const { exp } = decodeJWT(refreshJwt)
  const now = Date.now().valueOf() / 1000
  if (exp < now) {
    return null
  }

  return fetchJWT({ refreshJwt })
    .then(res => {
      Cookie.set('jwt', res.access_token)
      Cookie.set('rjwt', res.refresh_token)
      return res
    })
    .then(res => decodeJWT(res.access_token))
    .catch(err => {
      console.error(err)
      return null
    })
}

export async function fetchJWT({ email, password, refreshJwt } = {}) {
  let body, url
  if (email && password) {
    url = `${process.env.GATSBY_VENUS_BACK_URL}/api/public/auth/login`
    body = {
      email,
      password,
    }
  } else if (refreshJwt) {
    url = `${process.env.GATSBY_VENUS_BACK_URL}/api/public/auth/refresh-token`
    body = {
      refreshToken: refreshJwt,
    }
  } else {
    throw new ClientError(
      'Vous devez spécifier un email et mot de passe',
      `To get the JWT you need at least an email/password or a refreshJwt`,
    )
  }

  return await httpFetch(url, {
    method: 'POST',
    // Allow both CORS and same origin requests to work with cookies. Unfetch won't send or receive cookies otherwise.
    // see https://github.com/developit/unfetch#fetchurl-string-options-object
    credentials: 'include',
    noAuth: true, // we don't include the authorization header because the call is public and made to get authorized
    body,
  })
}

const AuthenticationContextProvider = ({ children, ...props }) => {
  const [user, setUser] = useState(undefined)

  async function login(email, password) {
    setUser(undefined)
    return await fetchJWT({ email, password })
      .then(res => {
        Cookie.set('jwt', res.access_token)
        Cookie.set('rjwt', res.refresh_token)
        return res
      })
      .then(res => decodeJWT(res.access_token))
      .then(authUser => {
        setUser(authUser)
        return authUser
      })
      .catch(err => {
        setUser(null)
        throw err
      })
  }

  async function logout() {
    Cookie.remove('jwt')
    Cookie.remove('rjwt')
    setUser(null)
    const fromPage = globalHistory.location
    globalHistory.navigate(`/${LOGIN}`, { next: fromPage })
  }

  useEffect(() => {
    const refreshJwt = Cookie.get('rjwt')
    getUserIfAuthenticated(refreshJwt).then(setUser)
  }, [])

  const value = {
    user,
    login,
    logout,
    getUserIfAuthenticated,
  }

  return (
    <AuthenticationContext.Provider value={value} {...props}>
      {children}
    </AuthenticationContext.Provider>
  )
}

const AuthenticationContextConsumer = AuthenticationContext.Consumer

export {
  AuthenticationContext,
  AuthenticationContextProvider,
  AuthenticationContextConsumer,
}
export default AuthenticationContext
