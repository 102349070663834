import fetch from 'isomorphic-unfetch'

function getSlackChannel() {
  let slackChannel
  switch (process.env.GATSBY_ENV_DISPLAY_NAME) {
    case 'prod':
      slackChannel = '#fr-dev_prod_notif'
      break
    case 'pp':
      slackChannel = '#fr-dev_pp_notif'
      break
    case 'staging':
      slackChannel = '#fr-dev_staging_notif'
      break
    default:
      slackChannel = '#fr-dev_local_notif'
  }

  return slackChannel
}

function buildDemandErrorBody(channel, demand, error) {
  const demandJson = JSON.stringify(demand)

  const errorBlock = function(label, value) {
    return {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `_${label}_ : ${JSON.stringify(value)}`,
      },
    }
  }

  const body = {
    channel: channel,
    username: 'command fail',
    text: demandJson,
    icon_emoji: ':x:',
    blocks: [
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text:
            ':warning: *Looks like there is a problem with the following demand:*',
        },
      },
      {
        type: 'divider',
      },
      {
        type: 'section',
        text: {
          type: 'plain_text',
          text: demandJson,
        },
      },
      {
        type: 'divider',
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: ':male-technologist: *Associated error:*',
        },
      },
    ],
  }

  // Add error blocks
  if (error instanceof Error) {
    body.blocks.push(errorBlock('Name', error.name))
    body.blocks.push(errorBlock('Message', error.message))
    body.blocks.push(errorBlock('Stacktrace', error.stack))
  } else {
    body.blocks.push(errorBlock('Message', error))
  }

  return body
}

function notifyDemandError(demand, error) {
  const body = buildDemandErrorBody(getSlackChannel(), demand, error)

  return fetch(
    'https://hooks.slack.com/services/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',
    {
      method: 'post',
      body: 'payload=' + JSON.stringify(body),
      headers: new Headers({
        'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      }),
    },
  )
}

export { notifyDemandError }
