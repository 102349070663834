// https://developer.yahoo.com/nativeandsearch/advertiser/guide/targeting/track-conversions/track-events-triggered-actions/

export default config => {
  const { projectId, pixelId } = config
  return {
    config,
    name: `yahoo`,
    snippet: `
    (function(w,d,t,r,u){
        w[u]=w[u]||[];w[u].push({'projectId':'${projectId}','properties':{'pixelId':'${pixelId}'}});
        var s=d.createElement(t);
        s.src=r;
        s.async=true;
        s.onload=s.onreadystatechange=function(){
            var y,rs=this.readyState,c=w[u];
            if(rs&&rs!="complete"&&rs!="loaded"){return}
            try{
                y=YAHOO.ywa.I13N.fireBeacon;
                w[u]=[];
                w[u].push=function(p){y([p])};
                y(c)
            }catch(e){}};
            var scr=d.getElementsByTagName(t)[0],par=scr.parentNode;
            par.insertBefore(s,scr)
        })(window,document,"script","https://s.yimg.com/wi/ytc.js","dotq");
  `,
    pageview: ({ pathname }) =>
      window.dotq.push({
        projectId,
        properties: {
          pixelId,
          qstrings: {
            et: 'custom',
            ea: 'pageview',
            ec: pathname,
          },
        },
      }),
    track: ({ eventName, extra = {} }) =>
      window.dotq.push({
        projectId,
        properties: {
          pixelId,
          qstrings: {
            et: 'custom',
            ea: eventName,
            ec: extra.category,
            el: extra.label,
          },
        },
      }),
  }
}
