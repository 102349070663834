import React, { createContext, useReducer } from 'react'

const PhoneContext = createContext()

const initialState = {
  local: process.env.GATSBY_PHONE_NUMBER,
  e164: process.env.GATSBY_PHONE_NUMBER,
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'UPDATE':
      return {
        ...state,
        ...payload,
      }
    default:
      return state
  }
}

function PhoneContextProvider({ state: injectedInitialState, ...props }) {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...injectedInitialState,
  })
  const value = { state, dispatch }

  return (
    <PhoneContext.Provider value={value}>
      {props.children}
    </PhoneContext.Provider>
  )
}

const PhoneContextConsumer = PhoneContext.Consumer

export { PhoneContext, PhoneContextProvider, PhoneContextConsumer }
export default PhoneContext
