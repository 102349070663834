/* eslint-disable no-console */
import { isBrowser } from '../util'

export default function includeAnalytics(
  includedScripts,
  { simulate = false, retryUntil = 10, retryInterval = 1000 } = {},
) {
  if (simulate) {
    console.info('📈 Analytics events are simulated, they will NOT be sent')
    console.groupCollapsed &&
      console.groupCollapsed(
        `📈 Loading ${Object.keys(includedScripts).length} scripts`,
      )
    Object.values(includedScripts).forEach(s => {
      console.log(`${s.name} with config: `, s.config)
    })
    console.groupEnd && console.groupEnd()
  }

  let queuedScripts = []

  // only run setInterval on the browser to prevent gatsby from leaving dangling jest-worker child processes
  // see https://github.com/gatsbyjs/gatsby/issues/7527
  if (isBrowser()) {
    setInterval(() => {
      queuedScripts = queuedScripts
        .map(item => {
          if (item.tryCount >= retryUntil) {
            console.log(
              `❌ stop retrying failed event '${item.event}' for '${item.script.name}'. original error below:`,
              item.params,
            )
            console.error(item.error)
            warnUnavailable(item.error, item.script)
            return null
          }
          item.tryCount++

          console.log(
            `🔁 (${item.tryCount}): retrying failed event '${item.event}' for '${item.script.name}' with params:`,
            item.params,
          )
          const triggered = execute(item.script, item.event, item.params, true)
          if (triggered) {
            console.log(
              `✅ sent event '${item.event}' for '${item.script.name}' with params:`,
              item.params,
            )
            return null
          }
          return item
        })
        .filter(item => item !== null)
    }, retryInterval)
  }

  function execute(script, event, params, retry = false) {
    try {
      if (script[event]) {
        simulate ? console.log(`${script.name}`) : script[event](params)
        return true
      }
    } catch (error) {
      if (!retry) {
        console.log(
          `Could not send '${event}' to ${script.name}. Will retry in ${retryInterval}ms`,
        )
        queuedScripts.push({
          script,
          event,
          time: new Date(),
          error,
          params,
          tryCount: 0,
        })
      }
    }
    return false
  }

  function warnUnavailable(error, script) {
    if (error instanceof ReferenceError) {
      console.warn(
        `${script.name} wasn't triggered because it may be unavailable (ad-blockers, devtools blocking, did you even include it ?)`,
      )
    }
  }

  function executeAll(scripts, event, params) {
    simulate &&
      console.groupCollapsed &&
      console.groupCollapsed(`📈 ${event}:`, params)
    let noTriggered = true
    scripts.forEach(script => {
      noTriggered = !execute(script, event, params) && noTriggered
    })
    if (noTriggered) {
      console.warn(
        `No analytics were triggered for '${event}', verify they have the '${event}' action available and that no error happended`,
      )
    }
    console.groupEnd && console.groupEnd()
  }

  return {
    pageview: extra => executeAll(includedScripts, 'pageview', extra),
    track: (eventName, extra) =>
      executeAll(includedScripts, 'track', { eventName, extra }),
    lead: extra => executeAll(includedScripts, 'lead', extra),
    user: extra => executeAll(includedScripts, 'user', extra),
  }
}
